.viewAllParts:focus {
    border: none;
    outline: none;
}

.column-about {
    float: left;
    width: 50%;
    line-height: 2;
}

/* Clear floats after the columns */
.row-about:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column-about {
        width: 100%;
        line-height: 2;
    }
}

.leading {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 1rem;
}
.subleading {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
}
.wd-80 {
    width: 80%;
}
.social {
    color: #1A0451 !important;
    padding: 10px;
}
.copyright {
    height: 32px;
    background-color: #F0F0F0;
    color: #333333;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.help-pop {
    position: absolute;
    right: 4.2rem;
    top: -11.4rem;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 200px;
}

.help-list {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    color: #333333;
}

.btn-help-view {
    display: flex;
    width: 100px !important; 
    background-color: #1A0451 !important; 
    color: #ffffff !important;
    border-radius: 30px !important;
    position: absolute !important; 
    right: 4rem !important; 
    top: -4rem !important;
    font-size: 14px;
}
@media screen and (max-width: 600px) {
    .btn-help-view {
        right: 1rem !important; 
    }
    .help-pop {
        right: 1.2rem;
        top: -12.4rem;
    }
    .wd-80 {
        width: 100%;
    }
}

.help-svg {
    margin-top: -3px;
    margin-right: 10px;
}
.showModal {
    display: block;
}
.hideModal {
    display: none;
}
.footer-view {
    background-color: #FFFCF7;
    border: 2px solid #F0F0F0;  
    margin-top: 5rem; 
    position: relative;
}
.upload {
    box-shadow: none !important;
}
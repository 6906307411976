@import '~react-toastify/dist/ReactToastify.css';

.Toastify__toast {
  background-color: #000;
  color: #fff;
  font-size: 0.833rem;
  min-height: 50px;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;

  @media (min-width: 480px) {
    &-container {
      width: 400px;
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    &-container {
      width: 400px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    &-container {
      width: 500px;
    }
  }

  &-body {
    margin: 0;
    padding: 0;

    .toast {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 8px;
      background: black;
      overflow-wrap: break-word;
      flex-wrap: wrap;
      word-break: break-all;

      &--message {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 30px;
        row-gap: 5px;
        align-items: center;
        margin: auto 0;
        top: 22%;
      }

      @media (min-width: 480px) {
        &--message {
          font-size: 14px;
        }
      }

      // Small devices (landscape phones, 576px and up)
      @media (min-width: 576px) {
        &--message {
          font-size: 12px;
        }
      }

      // Medium devices (tablets, 768px and up)
      @media (min-width: 768px) {
        &--message {
          font-size: 14px;
        }
      }

      &--buttons {
        float: right;
        top: 22%;
        right: 35px;
        align-items: center;
        justify-content: center;
        color: #ff8a3b;
        position: absolute;
      }

      @media (min-width: 480px) {
        &--buttons {
          top: 22%;
          font-size: 14px;
        }
      }

      // Small devices (landscape phones, 576px and up)
      @media (min-width: 576px) {
        &--buttons {
          top: 20%;
          font-size: 13px;
        }
      }

      // Medium devices (tablets, 768px and up)
      @media (min-width: 768px) {
        &--buttons {
          top: 22%;
          font-size: 14px;
        }
      }

      &--success {
        color: #fff;
      }

      &--error {
        color: #eb0026;
      }

      &--warning {
        color: #ff8a3b;
      }

      &--info {
        color: #3e8bfe;
      }

      &--notification {
        color: #012e78;
      }
    }
  }

  &--success,
  &--error,
  &--warning,
  &--info,
  &--notification {
    display: flex;
    align-items: center;
    padding-left: 1em;
  }

  &--success {
    color: #fff;
  }

  &--error {
    color: #eb0026;
  }

  &--warning {
    color: #ff8a3b;
  }

  &--info {
    color: #3e8bfe;
  }

  &--notification {
    color: #012e78;
  }

  .Toastify__close-button {
    position: absolute;
    right: 30px;
    top: 35%;
    transform: translate(-35%, -4px);
    color: #fff;
    background-color: #fff;
    margin: 0 0;
    font-size: 18px;
  }

  @media (min-width: 480px) {
    .Toastify__close-button > svg {
      width: 16px;
      height: 18px;
    }
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    .Toastify__close-button > svg {
      width: 14px;
      height: 16px;
    }
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .Toastify__close-button > svg {
      width: 16px;
      height: 18px;
    }
  }
}

/* HamburgerMenu.css */

.mobile-menu-container {
  display: none; /* Initially hide the menu */
}

@media screen and (max-width: 1025px) {
  .mobile-menu-container {
    display: block; /* Show the menu on mobile sizes */
  }
}

/* MobileMenu.css */

.mobile-menu {
  width: 100%;
  margin: 0 auto;
  padding: 12px;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  gap: 10px;
  font-size: 1.2em;
  color: #333333;
}

.greeting {
  margin-bottom: 6px;
  padding-left: 10px;
}

.small-font {
  font-size: 12px;
}

.bold-font {
  font-weight: bold;
  font-size: 22px;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.logo-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.icons {
  display: flex;
  gap: 20px;
  padding-right: 10px;
}

.flex-span {
  display: flex;
}

.mobilelinkText {
  color: #333333;
}

.logout {
  font-size: 14px;
  font-weight: 700;
  padding-left: 10
}
.custom-carousel-item {
  margin-right: 5px; /* Adjust this value to decrease the space between items */
  padding-left: 10px;
}

.carousel-item-data{
  height: 700px !important;
}

.carousel-item-data div:nth-child(2) {
  height: 700px !important;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-bottom: 10px;
  display: flex;
}

.carousel-item-data div:nth-child(2) >img {
  height: 100%;
  object-fit: contain;
}
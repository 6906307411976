.sku-subhead { 
    font-size: 10px; 
    font-weight: 400; 
    color: #6A6A6B; 
}
.sku-heading {
    font-size: 12px; 
    color: #333333;
    font-weight: 600;
}
.title-limit {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* height: 40px; */
}
.sku-currency {
    font-size: 11px; 
    color: #333333; 
    font-weight: 400;
}
.dot {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}
.sku-price {
    font-size: 14px;
    color: #333333; 
    font-weight: 700;
    margin-left: 6px;
}
.sku-warranty {
    font-size: 11px; 
    color: #333333; 
    font-weight: 400
}
.sku-cart {
    margin-top: 2rem;
}
.request-cart-btn {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
}
.product-slider-card {
    box-shadow: none !important;
    border: 1px solid #CACACA !important;
}
.order-details-table td {
  color: black !important;
}
.order-detail-table-title {
  font-weight: 600 !important;
  color: black !important;
}
.view-log-btn {
  color: #fff;
  background-color: #1d71b8;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  letter-spacing: 0.5px;
  margin-left: 5px;
}
.view-log-btn:hover {
  color: white;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  transition: 0.2s all ease-in-out;
}

.cancel-log-btn {
  color: #fff;
  background-color: #f39200;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  letter-spacing: 0.5px;
  margin-left: 5px;
  margin-top: 6px;
  padding: 0 28px;
}

.cancel-log-btn:hover {
  color: white;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  transition: 0.2s all ease-in-out;
}


.return-log-btn {
  color: #fff;
  background-color: #f00909;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-weight: 600;
  border-radius: 5px;
  letter-spacing: 0.5px;
  margin-left: 5px;
  margin-top: 6px;
  padding: 0 28px;
}

.return-log-btn:hover {
  color: white;
  box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.75);
  transition: 0.2s all ease-in-out;
}

.buyer-order-status {
  text-align: center;
  font-size: 10px;
  background-color: #6c757d;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
  text-transform: uppercase;

  font-weight: 600;
}

.preparing {
  background-color: #007bff;
}

.pending {
  background-color: #6c757d;
}

.cancelled {
  background-color: #f39200;
}

.outfordelivery {
  background-color: #135fb1;

}

.shippedtohub{
  background-color: #a733f5;
}

.receivedathub{
  background-color: #466b8b;
}

.Prep{
  background-color: #232F3E;
}

.issue {
  background-color: #8d0d33;
}

.delivered {
  background-color: #28a745;
}
.delayed, .on-hold {
  background-color: rgb(202, 202, 9);
}
.cancelled-product {
  text-decoration: line-through;
}

.removed-orderitem {
  font-weight: 500;
}

#hide input[type=file] {
  display:none;
  margin:10px;
  }
  #hide input[type=file] + label {
  display:inline-block;
  margin:20px;
  padding: 4px 32px;
  background-color: #FFFFFF;
  border:solid 1px #666F77;
  border-radius: 6px;
  color:#666F77;
  }
  #hide input[type=file]:active + label {
  background-image: none;
  background-color:#2D6C7A;
  color:#FFFFFF;
  }
.home-search { 
    display: inherit;
    img{
        width: 70px;
        padding-right: 15px;
    }

    .product-detail {
        display:flex;
        flex-wrap: wrap; 
    }
    .partno-detail {
        margin-top: auto;
        margin-bottom: auto;
    }
    .highlight {
        font-weight:bold;
      }
} 
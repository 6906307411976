
.dropdown-vin-menu {
    position: absolute;
    top: 100%;
    left: 0px;
    z-index: 2000;
    width: 83%;
    margin-top: 2px;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 162px;
    padding: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  
  .dropdown-vin-menu::-webkit-scrollbar {
    width: 10px; 
    height: 10px;
    background-color: #f5f5f5;
    display: none;
  }
  
  .dropdown-vin-menu::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  /* Changes the color of the track of the scrollbar */
  .dropdown-vin-menu::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  
  .dropdown-vin-menu li{
    display: block;
    cursor: pointer;
    padding: 10px 2px;
    font-size: 16px;
    color: #000;
    font-weight: 600;
    width: 100%;
  }
  
  .history-item {
    padding: 0 1rem;
  }
  
  .history-item p {
    margin-bottom: 0;
  }
  
  .brand-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-right: 1rem;
  }
  
  .dropdown-vin-menu .menu-title {
    margin-bottom: 4px;
    padding: 0 1rem;
    font-size: 1rem;
    color: #ccc;
  }
  
  .dropdown-vin-menu > li:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
  
  .dropdown-vin-menu li:hover {
    background-color: #f39200;
    color: #fff;
  }

  .search-ico {
    cursor: pointer;
    color: rgb(51, 51, 51);
    padding-right: 5px;
    margin-top: 12px;
  }
  
.card__sku-container {
    gap: 1rem;
    margin-top: 10px;
    margin-bottom: 20px;
}

.alias-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    flex: .75;
    text-align: right;
}
.MuiAutocomplete-clearIndicator:hover {
  background-color: transparent !important;
}
.MuiAutocomplete-clearIndicator {
  top: calc(50% - 19px) !important;
  background-color: transparent !important;
}
.MuiAutocomplete-popupIndicator:hover {
  background-color: transparent !important;
}
.MuiAutocomplete-endAdornment {
  top: calc(50% - 19px) !important;
}
.MuiAutocomplete-popupIndicatorOpen {
  bottom: 0 !important;
  left: 10 !important;
  display: none !important;
}

.bouncing-dot-loader {
  display: flex;
  justify-content: center;
  margin: 40px auto;
}

.bouncing-dot-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-dot-loader 0.6s infinite alternate;
}

@keyframes bouncing-dot-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-dot-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-dot-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.no-dropdown .react-select__indicators {display: none !important;}
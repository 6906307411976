.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.modal-header{
    float: left;
}

.push-right {
  float: left;
  margin-right: auto;
  justify-content: center;
  font-size: 15px;
}

.otp-input-container {
  position: relative;
}

.hide-controls input[type="number"]::-webkit-inner-spin-button,
.hide-controls input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hide-controls input[type="number"] {
  -moz-appearance: textfield;
}

.help-pop-modal {
  position: absolute;
  right: -2.8rem;
  bottom: -13px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border: 1px solid #CACACA;
  border-radius: 5px;
  width: 200px;
}

.help-content {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

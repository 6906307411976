.mobile-footer {
    display: none;
    @media (max-width: 1023px) {
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        padding: 10px 0;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    }
}

.footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-item span {
    font-size: 12px;
    margin-top: 5px;
    color: lightgray;
}


.footer-item.active span {
    color: black;
}

.footer-item svg {
    color: lightgray;
}

.footer-item.active svg {
    color: black;
}
.selection__input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #e3e6ef;
    border-radius: 0.2rem;
}

.selection__input-icon {
    color: #cd7b00;
    margin-top: 0;
    margin-left: .5rem;
}

.selection__input-input {
    border: none;
    flex-grow: 2;
    padding: 0 1.2rem;
    background-color: #fff !important;
    cursor: pointer;
    text-overflow: ellipsis;
}

.selection__input-input:focus {
    box-shadow: none;
    border-color: rgba(39, 43, 65, 0.35);
}

.input-padding {
    padding: 0 0.5rem;
}

.text-button {
    color: rgb(243, 146, 0);
    margin-bottom: 0;
    padding: 0 .5rem;
    cursor: pointer;
}

.text-button:hover {
    color: rgb(255, 183, 75);
}
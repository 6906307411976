/* Styles for category select */

.brand__category-select {
    margin: 2rem 0;
    border: none;
    background: #fff;
    border-radius: 10px;
    max-width: fit-content;
}

.catalog__type-select {
    margin: 2rem 0;
    border: none;
    border-radius: 4px;
    background: #F9F9F9;
    /* max-width: fit-content; */
    width: 766px;
    height: 48px;
    flex-shrink: 0;
    color: var(--ui-black, #333);
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
}

.brand__category-list {
    list-style: none;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    overflow-x: auto;
}

.catalog__type-list {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 6px, 8px, 6px, 8px;
    overflow-x: auto;
    border-radius: 4px;
    background: #F9F9F9 !important;
}

.brand__category-list_item {
    max-width: max-content;
    min-width: 150px;
    text-align: center;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem 0;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 .5rem;
}

.catalog__type-list_item {
    display: flex;
    width: 144px;
    height: 36px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: var(--ui-grey-7, #F9F9F9);
}

.brand__category-list_item:hover {
    color: #f39200;
}

.catalog__type-list_item:hover {
    /* color: #f39200; */
    cursor: pointer;
}

.brand__category-list .active__category {
    background: #f3f3f3;
    color: #f39200;
    transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.catalog__type-list .active__category {
    width: 144px;
    height: 45px;
    flex-shrink: 0;
    stroke-width: 4px;
    stroke: var(--ui-blue, #1D71B8);
    background-image: url(../../../../catalog-active.svg);
    background-size: 60% 184%;
}

/* Styles for subCategory select */

.brand__subCategory-select {
    margin-bottom: 2rem;
    min-width: 480px;
    max-width: fit-content;
    text-align: center;
}

.brand__subCategory-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    transition: all 1s ease;
}

.brand__subCategory-item {
    color: #000;
    font-size: .8rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.active__subCategory {
    color: #f39200;
}

.brand__subCategory-item:hover {
    color: #f39200;
}

.subCategory__icon {
    margin-top: 0;
}

.brand__subCategory-item_label {
    margin-left: .5rem;
    margin-bottom: 0;
}


/* styles for brand list */
.brand-card {
    transition: all 0.3s ease;
}

.brand-card:hover {
    transform: scale(1.02) perspective(0px);
}

.search__make-list {
    margin-top: 1rem;
}

.fadeIn {
    animation: fadeInAnimation 2s;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (max-width: 600px) {

    .brand__category-select,
    .brand__subCategory-select {
        max-width: 100%;
        min-width: 100%;
    }

    .brand__category-list_item {
        max-width: 100%;
        min-width: calc(100% / 3);
    }

    .brand__subCategory-list {
        flex-direction: column;
        align-items: center;
    }

}

@media (max-width: 350px) {
    .brand__category-list_item {
        min-width: 50%;
    }
}

.grid8 {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 1%;
    margin-top: 8px;
/*     max-height: 210px; */
    overflow-y: scroll;
}
@media (max-width: 764px) {
    .grid8 {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (min-width: 1920px) {
    .grid8 {
        max-height: 290px;
    }
}
.grid8::-webkit-scrollbar {
    display: none;
}

.grid3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1%;
    margin-top: 8px;
}

.parts-view {
    margin-top: 5px !important;
/*     max-height: 500px; */
    overflow-y: scroll;
}
.parts-view::-webkit-scrollbar {
    display: none;
}

.square {
    display: flex;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 3px;
}

.square img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    padding: 16px;
}

.square.fullImg {
    padding: 0;
}

.square.fullImg img {
    object-fit: cover;
}

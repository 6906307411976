.discussion-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 70px;
    height: 70px;
    position: fixed;
    bottom: 55px;
    right: 50px;
    z-index: 3;
    background: #ffff;
    border-radius: 70px;
    box-shadow: 1px 3px 15px #aaa;
    border-color: transparent;
}

.chat-discussion {
    border-radius: 10px;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    max-width: 600px;
    min-width: 340px;
    width: 100%;
    position: fixed;
    z-index: 4;
    top: unset;
}

.chat-with-container{
    position: fixed;
    bottom: 70px;
    right: 140px;
    background: white;
    padding: 10px 20px;
    border-radius: 10px;
    z-index: 3;
    box-shadow: 1px 3px 15px #aaa;
}

.chat-with-container::after{
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    right: -10px;
    top: 10px;
    bottom: auto;
    border: 5px solid;
    border-color: lightyellow transparent transparent lightyellow;
}

.whatsapp-icon{
    position: fixed;
    bottom: 50px;
    right: 60px;
    background: #21d741;
    border-radius: 10px;
    z-index: 3;
    box-shadow: 1px 3px 15px #aaa;
}


.whatsapp-icon-share{
    background: #21d741;
    border-radius: 10px;
    z-index: 3;
    box-shadow: 1px 3px 15px #aaa;
    margin-top: 20px;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.wrapper {
  font-family: "Montserrat", sans-serif;
}
.innerpage-banner {
  height: 200px !important;
  display: flex;
  align-items: center;
  color: #1a0451;
}
.cover {
  background-size: cover;
  background-position: center center;
}
.breadcrumb-wrapper h1 {
  color: #fff;
  font-weight: 32px;
}
.content-wrapper {
  padding-bottom: 80px;
}
.content-wrapper h1 {
  color: #1a0451;
  text-transform: capitalize;
  font-size: 32px;
  margin-top: 80px;
}
.faq-list-wrapper {
  margin-top: 50px;
}

/*yellow color code => #f39200 */
.faq-list {
  margin-bottom: 25px;
}
.faq-list-wrapper {
  margin-top: 60px;
}
.title-wrapper {
  font-size: 18px;
  font-weight: 700;
}
button.inactive,
button.active {
  background-color: transparent;
  border: 0;
  outline: none;
  font-weight: 600;
  color: #1a0451;
  position: relative;
  padding-left: 55px;
}
button.active .plus {
  background-color: #f39200 !important;
  position: relative;
  text-align: center;
  top: 5px !important;
}
button.active .plus::after {
  position: absolute;
  content: "+";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
button.inactive .minus {
  position: relative;
}
button.inactive .minus::after {
  position: absolute;
  content: "-";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #212121;
}
button.inactive .minus,
button.active .minus,
button.inactive .plus,
button.active .plus {
  height: 40px;
  width: 40px;
  background-color: #ddd;
  position: absolute;
  left: 0px;
  display: block;
  top: -5px;
}
.panel-close {
  box-sizing: border-box;
  padding: 0.5rem 1rem 0 1rem;
  opacity: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease-in;
  transition: all 1s;
  padding-left: 55px;
}

.panel-open {
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  padding: 1rem;
  margin-bottom: 30px;
  box-sizing: border-box;
  opacity: 1;
  color: #1a0451;
  width: 100%;
  height: auto;
  transition-timing-function: ease-in;
  transition: all 1s;
  padding-left: 55px;
  font-weight: 500;
}
.faq-list:last-of-type .panel-open {
  border: 0px !important;
}
.main-container {
  /* width: 375px; */
  height: 132px;
  flex-shrink: 0;
  padding: 0 0 500px 0;
}

.content {
  min-width : "1024px";
  text-align: left;
  border-bottom: 0.5px solid var(--UI-Grey-2, #BCBCBC);
  /* display: flex; */
  /* width: 327px; */
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  color: var(--UI-Black, #333);

/* Mobile/Body 1 */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  cursor: pointer;
}
/* .content :hover {
  cursor: pointer;
} */

.inner-content {
  display: flex;
  padding:0 0 0 0;
}

.inner-content-arrow {
  display: flex;
  float: right;
}
.inner-content-text {
  display: flex;
  margin: 5px 0 0 10px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  row-gap: 20px;
  /* background-color: #333333; */
}

li {
  float: left;
}

.contact-main {
  color: var(--UI-Black, #333);
  /* Mobile/H1 */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}
.contact-sub {
  color: var(--UI-Black, #333);

  /* Mobile/Body 1 */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}
.contact-content {
  /* border-radius: 8px; */
  /* background: #F9F9F9;  */
  min-width: 370px;
  padding: 16px 12px;
}
.inner-content-container {
  /* display: flex; */
  padding: 16px 12px;
  /* margin: 0 0 0 16px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  /* width: 344px; */
  border-radius: 8px;
  background: #F9F9F9;
  border-radius: 10;
}

.content-icon-cover {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  fill: var(--White, #FFF);
  stroke-width: 0.5px;
  stroke: var(--UI-Grey-4, #E5E5E5);
  text-align: center;
  border-color: #212121;
  border-width: 0.5px;
  border: 1px solid #E5E5E5; 
  border-radius: 50%; 
  margin: 0 10px 0 0;
  background: #FFF;
  padding: 0 0 0 7px;
}

.content-icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  color: #333333;
}
.contact-text {
  color: var(--UI-Grey-1, #6A6A6B);
  font-family: Open Sans;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 109.091% */
}
.contact-text-sub {
  color: var(--UI-Black, #333);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 112.5% */
}

.location__select-modal {
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.location__suggestion-ul {
    position: absolute;
    z-index: 10000;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 200px;
    min-height: 200px;
    padding: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 94%;
}

.location__suggestion-ul::-webkit-scrollbar {
    width: 10px; 
    height: 10px;
    background-color: #420404;
  }
  
  .location__suggestion-ul::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  /* Changes the color of the track of the scrollbar */
  .location__suggestion-ul::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

.location__suggestion-li {
    display: flex;
    gap: 10px;
    cursor: pointer;
    padding: 10px 2px;
    font-size: 14px;
}

.location__suggestion-icon {
    font-size: 14px !important;
}

.location__suggestion-ul > li:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
  
.location__suggestion-ul li:hover {
    background-color: #f39200;
    color: #fff;
}

@media (max-width: 992px) {
    .location__select-modal {
        flex-direction: column;
    }
}

/* select location modal */

.select__location-container {
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
}

.select__location-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e3e6ef;
  border-radius: 8px;
  padding: 1rem 2rem;
  cursor: pointer; 
}
.active__type,
.select__location-card:hover {
  border: 1px solid rgb(243, 146, 0);
  background-color: rgb(240, 164, 49);
  color: #fff;
}
/* style for search city input */
.city__suggestion-ul {
    position: absolute;
    z-index: 1000;
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 200px;
    min-height: 200px;
    padding: 10px 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
}

.city__suggestion-ul::-webkit-scrollbar {
    width: 10px; 
    height: 10px;
    background-color: #420404;
  }
  
  .city__suggestion-ul::-webkit-scrollbar-thumb {
    background-color: #7A82A6;
    border-radius: 5px;
  }

.city__suggestion-li {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 8px 2px;
    font-size: 14px;
}

.city__suggestion-icon {
    font-size: 12px !important;
    margin-top: 0px;
}

.city__suggestion-main {
    color: #242424;
}

.city__suggestion-ul > li:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
  
.city__suggestion-ul li:hover {
    background-color: #e9e9e9e9;
}

.invoice-tax-header {
  display: flex;
  justify-content: space-between;
}

.company_logo {
  display: flex;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.location-details {
  font-weight: 700;
  color: black;
  font-size: 16px;
  font-family: "Monstract";
}
.tax-title {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  color: black;
  display: flex;
  justify-content: center;
}

.users-details {
  display: flex;
  justify-content: space-between;
  color: black;
  margin-top: 20px;
}

.tax-invoice-to,
.deliver-to,
.invoice-details-container {
  border: 1px solid black;
}

.deliver-to {
  margin-top: 20px;
  height: 12rem;
}
.tax-invoice-title {
  background-color: #bdbdbd;
  font-weight: 500;
  padding: 0 5px;
  border-bottom: 1px solid black;
}

.left {
  width: 49%;
}
.right {
  width: 49%;
}

.tax-info-detail {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  padding: 0 5px;
}

.item-table-container {
  margin-top: 20px;
}

.invoice-tax-table {
  width: 100%;
  color: black;
  border: 1px solid black;
}
.react-responsive-modal-modal {
  max-width: 1000px !important;
}

.einvoice-note {
  color: black;
  margin-top: 20px;
}
.einvoice-note ul {
  padding-inline-start: 16px;
}

.modal-title {
  width: 100%;
}

@media (max-width:500px) {
  .users-details{
    flex-direction: column;
  }
  .left{
    margin-bottom: 20px;
    width: 100%;
  }
  .right{
    width: 100%;
  }
}

@media (max-width:400px) {
  .item-table-container{
    overflow: scroll;
  }
}
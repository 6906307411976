.drawer-container {
  font-family: "Montserrat";
  height: 100%;
  width: 650px;
  max-width: 650px;
  position: relative;
}
.drawer-header {
  padding: 20px;
  display: flex;
}
.item-image {
  max-width: 110px;
  max-height: 100px;
  width: 110px;
  height: 100px;
}
.item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item-title {
  font-family: "Monsterrat";
  font-size: 16px;
  color: #272b41;
  font-weight: 700;
}
.drawer-horizontalline {
  border: 2px solid #e6edf0;
}

.drawer-filtercontainer {
  background-color: #f7fafa;
  padding: 10px 20px;
  border-bottom: 1px solid #e6edf0;
}
.drawer-filtertext {
  color: #272b41;
  font-weight: 600;
}
.item-container {
  padding: 20px;
}
.item-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6edf0;
  padding-bottom: 20px;
}
.item-description {
  padding: 20px 0;
}
.item-rating {
  display: flex;
}

.item-shipdescription {
  display: flex;
  justify-content: space-between;
}
.item-shippingdescription {
  width: 185px;
}
.item-solddescription {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.add-to-btn {
  background-color: rgb(243, 146, 0);
  color: white;
}

.drawer-end {
  padding: 10px 20px;
}

.mt-auto {
  margin: auto 0;
}

.currency {
  color: rgb(243, 146, 0);
  font-weight: 700;
  font-size: 10px;
}
.price {
  font-weight: 700;
  font-size: 16px;
  color: #272b41;
  margin-left: 5px;
}

.item-shipby,
.item-soldby {
  width: 90px;
}

.sticky-drawer {
  position: fixed;
  top: 0;
  width: 100%;
  transition: 0.2 all ease-in;
  padding: 10px !important;
}

  .maintainance__container {
      padding: 8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333;
      max-width: 1250px;
      margin: 0 auto;
  }

  .maintainance__img-container {
      text-align: center;
      margin-bottom: 2rem;
  }

  .maintainance__img-container img {
      border-radius: 50%;
  }

  .maintainance__body-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 20px;
      margin-top: 2rem;
  }

  .maintainance__body-container .text-div {
      text-align: center;
      font-weight: 500;
  }

  .contact_container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 3rem;
      gap: 1rem;
      width: 100%;
  }

  .contact_container .contact_detail {
      display: flex;
      color: #333;
      gap: .5rem;
      cursor: pointer;
  }

  .contact_container a:first-child {
    justify-content: flex-end;
  }

  .contact_container a:nth-child(3) {
    justify-content: flex-start;
  }

  .contact_container p {
      margin-bottom: 0;
      text-align: center;
  }

  .contact_container .contact_detail p {
      font-weight: 500;
      text-decoration: underline;
  }

  .maintainance__body-container .text-div a:hover {
      color: #333;
  }

  .text-footer {
      margin-top: 5rem;
      font-weight: 600;
  }

  @media (max-width: 620px) {
      .maintainance__container {
          padding: 2rem;
      }
  }


  @media (max-width: 695px) {
      .maintainance__body-container {
          font-size: 16px;
      }

      .contact_container .contact_detail p {
          font-size: 16px;
      }

      .contact_container {
          flex-direction: column;
          align-items: center;
      }
  }
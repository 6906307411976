.svg_icons {
  color: #333333;
}

.img-logo {
  width: 65%;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .iconView {
    display: flex;
    gap: 28px;
    justify-content: end;
    padding-top: 8px;
    color: #333333;
  }
}

@media screen and (max-width: 1025px) {
  .mobile-container {
    display: block;
  }

  .web-container {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .mobile-container {
    display: none;
  }

  .web-container {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .tab-container {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tab-container {
    display: block;
  }
}

@media screen and (min-width: 760px) and (max-width: 1023px) {
  .header-user {
    display: block;
  }

  .offcanvas-menu-resp {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .header-user {
    display: flex;
    justify-content: right;
    margin-right: 10px;
  }

  .offcanvas-menu-resp {
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
  }
}

.section-title {
  margin-bottom: 1rem;
}

.userName {
  text-overflow: ellipsis;
  max-width: 10ch;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
}

.user-login {
  display: flex;
    justify-content: end;
    padding-right: 10px;
    margin-top: 8%;
}

.content-wrapper h1 {
  color: #1a0451;
  text-transform: capitalize;
  font-size: 32px;
  margin-top: 80px;
}

.termsandconditions-item-wrapper {
  margin-top: 50px;
  list-style: none;
}

.termsandconditions-item-wrapper>li {
  position: relative;
  margin-bottom: 30px;
}

.inner-list {
  list-style-type: upper-alpha;
  padding-left: 15px;
}

.inner-list li {
  color: #1a0451;
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
}

.termsandconditions-item-wrapper>li span {
  position: absolute;
  left: 0px;
  width: 40px;
  height: 40px;
  display: block;
  background: #f39200;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.termsandconditions-content {
  padding-top: 0px;
}

.termsandconditions-content h3 {
  color: #1a0451;
  margin-bottom: 10px;
}

.termsandconditions-content p {
  color: #1a0451;
  margin-bottom: 10px;
  font-weight: 500;
}